import { RELATIVE_API_URL } from '../constants/api';
import { request, setInstance } from './request';

export const getSettingsApiUrl = (instance, compId, externalId) =>
  `${RELATIVE_API_URL}/_api/settings?instance=${instance}&compId=${compId}&externalId=${externalId}&viewMode=${
    externalId === 'published' ? 'site' : 'editor'
  }`;

export const fetchSettings = async (instance, compId, externalId) => {
  setInstance(instance);
  return request(getSettingsApiUrl(instance, compId, externalId)).catch((err) => {
    throw Error(err.request.statusText);
  });
};

export const patchSettings = async (instance, compId, externalId, settings) => {
  setInstance(instance);
  return request.patch(getSettingsApiUrl(instance, compId, externalId), settings).catch((err) => {
    throw Error(err.request.statusText);
  });
};
