import { throttle } from 'lodash';
import { UPDATE_FULL_POST_STYLE, SETTINGS_EVENT_ID } from '@wix/communities-blog-client-common';
import { EXPERIMENT_USE_POST_PAGE_STYLE_IN_FULLPOST } from '@wix/communities-blog-experiments';
import { COMPONENT_DATA_CHANGED, SITE_WAS_SAVED } from '../constants/events';
import concurentEditing from './concurent-editing';
import experiments from './experiments';
import { setFullpostStyleParams } from './fullpost-layout-design-service';
import { savePostPageStyle } from './post-page-style';

export const addEventListeners = async (context) => {
  const { sdk } = context;
  await sdk.addEventListener(SITE_WAS_SAVED, async () => {
    try {
      await savePostPageStyle(context);
    } catch (e) {}
  });

  if (!experiments.isEnabled(EXPERIMENT_USE_POST_PAGE_STYLE_IN_FULLPOST)) {
    return;
  }

  const throttledSetParams = throttle(setFullpostStyleParams, 1000);
  await sdk.addEventListener(COMPONENT_DATA_CHANGED, async (event) => {
    const eventType = event?.detail?.previousData?.content?.[SETTINGS_EVENT_ID]?.payload;

    if (eventType === UPDATE_FULL_POST_STYLE) {
      await concurentEditing.withApproval(async () => {
        throttledSetParams(context);
      });
    }
  });
};
